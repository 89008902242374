.lojasArea {
  position: relative;
  .homeTitle {
    color: $primary;
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 15px;
  }
  .carouselDestaque {
    margin-left: -5px;
    .ImageCarousel {
      padding: 5px 5px 10px 5px;
      .borderCarousel {
        align-items: center;
        box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: row;
        margin: 0 5px;
        position: relative;
        width: 100%;
      }
      .image {
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        border-radius: 5px;
        height: 150px;
        width: 40%;
      }
      .imageCover {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 5px;
        height: 150px;
        width: 40%;
      }
      .info {
        min-height: 170px;
        width: 60%;
        &Dados {
          align-items: center;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: center;
          min-height: 170px;
          padding: 10px;
        }
        .productName {
          color: $primary;
          font-weight: 900;
          font-size: 14px;
          line-height: normal;
          word-break: break-word;
          width: 100%;
        }
        .productValue {
          color: $text;
          font-weight: bold;
          font-size: 14px;
          line-height: normal;
        }
        .lead {
          color: $text;
          font-size: 14px;
          line-height: normal;
          margin-bottom: 10px;
          margin-top: 10px;
          min-height: 35px;
        }
        .stars {
          width: 100%;
          img {
            max-height: 25px;
          }
        }
      }
    }
    .iconSelected {
      position: absolute;
      left: 20px;
      top: 10px;
      img {
        max-width: 25px;
      }
    }
  }
  .divideTitle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
  .verTodos {
    color: $primary;
    font-family: $font-family;
    font-size: 13px;
    font-weight: bold;
    padding-top: 8px;
    a {
      color: $primary;
      font-family: $font-family;
      font-size: 13px;
      font-weight: bold;
    }
  }
  .lanchesArea {
    margin-top: 15px;
    &Carousel {
      margin-top: -5px;
      .boxCarousel {
        align-items: center;
        // box-shadow: 2px 2px 9px 2px rgba(0, 0, 0, 0.1);
        display: flex;
        justify-content: center;
        margin: 10px 10px 10px 0px;
        width: 100%;
        .image {
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.1);
          height: 90px;
          width: 94%;
        }
        .imageCover {
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.1);
          height: 90px;
          width: 94%;
        }
      }
      .nome {
        color: $primary;
        font-size: 13px;
        font-weight: bold;
        line-height: normal;
        padding: 0 5px;
        word-break: break-word;
      }
      .valor {
        color: $text;
        font-size: 14px;
        font-weight: bold;
        line-height: normal;
        padding: 0 5px;
      }
      .relativeItem {
        position: relative;
        width: 100%;
        float: left;
      }
      .iconSelected {
        position: absolute;
        right: 10px;
        top: 10px;
        img {
          max-width: 20px;
        }
      }
    }
  }
  .bebidasArea {
    margin-top: 15px;
    .bebidasCarousel {
      margin-left: -5px;
      .imageBig {
        margin: 0 5px;
        border-radius: 5px;
        overflow: hidden;
        .imageBigItem {
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          height: 150px;
          width: 100%;
        }
      }
      .relativeItem {
        position: relative;
      }
      .iconSelected {
        position: absolute;
        right: 10px;
        top: 5px;
        img {
          max-width: 25px;
        }
      }
    }
  }
  .areaSelected {
    background-color: transparent;
    bottom: 15px;
    left: 5%;
    position: fixed;
    width: 90%;
    .areaSelectedBox {
      align-items: center;
      background-color: $primary;
      border-radius: 5px;
      display: flex;
      justify-content: space-between;
      padding: 15px 10px;
      .quantArea {
        color: $primary;
        align-items: center;
        background-color: white;
        border-radius: 5px;
        display: flex;
        height: 35px;
        justify-content: center;
        width: 35px;
      }
      .valueArea {
        color: white;
        font-size: 18px;
        font-weight: bold;
      }
      .nextArea {
        img {
          max-width: 22px;
        }
      }
    }
  }
  .spaceFixed {
    margin-top: 80px;
  }
}
.produtosList {
  ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }
  li {
    margin-bottom: 10px;
    margin-left: 0.5%;
    margin-right: 0.5%;
    width: 32%;
    .largura {
      width: 100%;
    }
    .boxProdutoImage {
      align-items: center;
      box-shadow: 2px 2px 9px 2px rgba(0, 0, 0, 0.1);
      display: flex;
      justify-content: center;
      margin: 0 1px 5px 1px;
      padding: 5px;
      .image {
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        height: 70px;
        width: 100%;
      }
      .imageCover {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 70px;
        width: 100%;
      }
    }
    .nome {
      color: $primary;
      font-size: 13px;
      font-weight: bold;
      line-height: normal;
      padding: 0 5px;
      word-break: break-word;
    }
    .valor {
      color: $text;
      font-size: 14px;
      font-weight: bold;
      line-height: normal;
      padding: 0 5px;
    }
  }

}

.destaquesList {
  ul {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }
  li {
    margin-bottom: 10px;
    width: 100%;
    .destaqueImagem {
      align-items: center;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      overflow: hidden;
      width: 100%;
      img {
        max-height: 200px;
      }
    }
  }
}
.detalheProduto {
  .productImage {
    margin-bottom: 15px;
    text-align: center;
    img {
      max-height: 200px;
    }
  }
  .productValor {
    color: $primary;
    font-size: 18px;
    margin-top: 15px;
  }
  .addInfo {
    margin-top: 15px;
    form {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    input {
      border-radius: 0;
      border-left: none;
      border-right: none;
      border-top: none;
      border-bottom: 1px $primary solid;
      color: $text;
      outline: none;
      font-size: 16px;
      padding: 5px 0;
      width: 100%;
    }
    button {
      background-color: $primary;
      border: 1px $primary solid;
      border-radius: 3px;
      color: white;
      box-shadow: none;
      font-size: 14px;
      margin-top: 5px;
      padding: 8px 7px 9px 7px;
      width: 100%;
    }
  }
  .observacoes {
    margin-top: 20px;
    ul {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      li {
        align-items: center;
        background-color: $primary;
        border-radius: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 10px;
        padding: 6px 10px;
        .texto {
          color: white;
          line-height: normal;
          padding-left: 5px;
          padding-right: 5px;
          width: calc(98% - 30px);
        }
        .button {
          margin-top: 2px;
          text-align: right;
          width: 30px;
          img {
            max-width: 20px;
          }
        }
      }
    }
  }
  .addProduct {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 5px;
    margin-top: 20px;
    width: 100%;
    .moreLess {
      align-items: center;
      border: 1px $primary solid;
      border-radius: 5px;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      padding: 5px 10px;
      width: 28%;
      .less {
        img {
          max-width: 20px;
        }
      }
      .quant {
        align-items: center;
        color: $primary;
        display: flex;
        font-size: 18px;
        justify-content: center;
        width: 30px;
      }
      .more {
        img {
          max-width: 20px;
        }
      }
    }
    .addInfoProduct {
      align-items: center;
      background-color: $primary;
      border-radius: 5px;
      display: flex;
      height: 50px;
      justify-content: space-between;
      width: 60%;
      .addTitle {
        color: white;
        padding-left: 10px;
      }
      .productValue {
        color: white;
        padding-right: 10px;
      }
    }
  }
}
.busca_otop {
  margin: 0 0 20px 0;
}
.title_infoProduct {
  color: $primary;
  font-weight: 800;
  margin-bottom: 15px;
}
.description_product {
  color: $text;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}
.centerItens {
  text-align: center;
  width: 100%;
}