.perfil {
  .perfilUser {
    align-items: center;
    background: white;
    background: linear-gradient(90deg, rgba(0,0,0,0.5) 0%, #000 24%);
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 15px 0;
    width: 100%;
    .imageArea {
      align-items: center;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .menuPerfilArea {
        border-radius: 4px;
        background-color: #000;
        height: 42px;
        margin-left: 15px;
        overflow: hidden;
        width: 42px;
      }
      .menuPerfil {
        background-size: cover;
        background-position: center;
        height: 42px;
        width: 42px;
      }
      .infoUser {
        margin-left: 15px;
        .nome {
          color: white;
          font-size: 16px;
          font-weight: bold;
        }
        .edit {
          font-size: 14px;
          margin-top: 3px;
          color: white;
        }
      }
    }
    .edit {
      margin-right: 15px;
    }
  }
  .boxWhiteArea {
    align-items: center;
    box-shadow: 2px 2px 9px 2px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 15px 0;
    width: 100%;
    .itemTitle {
      color: $text;
      font-size: 18px;
      font-weight: 300;
      padding-left: 15px;
    }
    .rightItem {
      padding-right: 15px;
    }
  }
  .boxBlackArea {
    align-items: center;
    background-color: $primary;
    box-shadow: 2px 2px 9px 2px rgba(0, 0, 0, 0.48);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 15px 0;
    width: 100%;
    .itemTitle {
      color: white;
      font-size: 18px;
      font-weight: 300;
      padding-left: 15px;
    }
    .rightItem {
      padding-right: 15px;
    }
  }
  img {
    max-width: 10px;
  }
}
.perfilEditar {
  position: relative;
  &:before {
    background-color: $primary;
    content: '';
    left: 0;
    height: 50%;
    position: absolute;
    top: -1px;
    z-index: -1;
    width: 100%;
  }
  .editImageArea {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    .menuPerfilArea {
      border-radius: 4px;
      border: 1px white solid;
      background-color: #000;
      height: 80px;
      margin-left: 0px;
      overflow: hidden;
      width: 80px;
    }
    .menuPerfil {
      background-size: cover;
      background-position: center;
      height: 80px;
      width: 80px;
    }
    .editText {
      color: $primary;
      font-size: 14px;
      margin-top: 5px;
      text-align: center;
      width: 100%;
    }
  }
}
.perfilAreaUser {
  .attButton {
    background-color: $primary;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 10px;
    margin-top: 5px;
    padding: 15px;
    width: 100%;
  }
}
.sairApp {
  align-items: center;
  background-color: #d4d4d4;
  display: flex;
  height: 100%;
  left: 0;
  justify-content: center;
  position: fixed;
  top: 0;
  transition: all 0.3s;
  width: 100%;
  .sairBoxCenter {
    align-items: center;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 15px;
    .sairBoxArea {
      align-items: center;
      background-color: white;
      border-radius: 5px 5px 0 0;
      display: flex;
      height: 100px;
      justify-content: center;
      width: 100%;
    }
    .sairText {
      color: $text;
      font-size: 16px;
      text-align: center;
      width: 100%;
    }
  }
  .sairNao {
    background-color: white;
    border-radius: 5px 0 0 5px;
    border: 1px $primary solid;
    color: $text;
    font-size: 16px;
    padding: 15px 10px;
    text-align: center;
    width: 50%;
  }
  .sairSim {
    background-color: $primary;
    border-radius: 0 5px 5px 0;
    border: 1px $primary solid;
    color: white;
    font-size: 16px;
    padding: 15px 10px;
    text-align: center;
    width: 50%;
  }
}
.hide {
  left: -100vw!important;
  transition: all 0.3s;
}
.uppercase_card {
  text-transform: uppercase;
}
.marginCard {
  margin-bottom: 5px;
}
.remover_card {
  padding-right: 20px;
  img {
    margin-top: 2px;
    min-width: 16px;
  }
}
.payNormal {
  bottom: 0!important;
  margin-top: 20px;
  left: 0!important;
  position: relative!important;
  button {
    width: 100%!important;
  }
}
.apagarConta {
  margin-top: 15px;
  font-size: 15px;
  text-align: right;
  text-decoration: underline;
}