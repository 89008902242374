.user {
 .userCode {
    margin-top: 20px;
    text-align: center;
    width: 100%;
    img {
      margin-bottom: 10px;    
      max-height: 170px;
    }
    .userValidade {
      color: $text;
      font-size: 14px;
      font-weight: 300;
      margin-bottom: 25px;
    }
  }
  .userName {
    align-items: center;
    color: $primary;
    display: flex;
    font-size: 24px;
    font-weight: bold;
    line-height: normal;
    margin-bottom: 15px;
    width: 100%;
    img {
      margin-left: 5px;
    }
  }
  .entregaArea {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    width: 100%;
    .itemLeft {
      color: $primary;
      font-size: 18px;
      font-weight: bold;
      text-align: left;
    }
    .itemRight {
      color: $primary;
      font-size: 18px;
      font-weight: bold;
      text-align: right;
    }
  }
  .redirectButtons {
    margin-top: 15px;
    .homeButton {
      color: white;
      font-size: 16px;
      font-weight: bold;
      background-color: $primary;
      border-radius: 5px;
      border: none;
      margin-bottom: 15px;
      padding: 15px 10px;
      width: 100%;
    }
    .pedidosButton {
      color: $primary;
      font-size: 16px;
      font-weight: 300;
      background-color: white;
      border-radius: 5px;
      border: 1px $primary solid;
      padding: 15px 10px;
      width: 100%;
    }
  }
}
.qrcode_area {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  .item {
    max-width: 200px;
    max-height: 200px;
    width: 200px;
    margin-bottom: 15px;
  }
}