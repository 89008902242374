.sliderArea {
    height: 95vh;
    overflow: hidden;
    padding: 20px;
    position: relative;
    img {
        max-height: 350px;
        margin-bottom: 15px;
    }
    .title {
        color: $primary;
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 20px;
        width: 100%;
    }
    .text {
        text-align: center;
        line-height: 18px;
        color: $text;
        font-size: 14px;
    }
    .dotsArea {
        bottom: -40px;
        display: flex;
        position: absolute;
        justify-content: center;
        width: 100%;
        button {
            background-color: $secondary;
            border: none;
            border-radius: 100%;
            margin: 0 10px;
            height: 12px;
            width: 12px;
        }
        .carousel__dot--selected {
            background-color: $primary;
        }
    }
}
.centerSlider {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    width: 100%;
    .pularHome {
        bottom: -42px;
        color: $text;
        left: 0;
        position: absolute;
        z-index: 99;
    }
    .NextButton {
        border: none;
        background: none;
        bottom: -45px;
        color: $primary;
        font-size: 17px;
        font-weight: bold;
        position: absolute;
        right: 0;
    }
    .prontoHome {
        bottom: -45px;
        color: $primary;
        font-size: 17px;
        font-weight: bold;
        position: absolute;
        right: 0;
    }
}