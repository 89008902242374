.notifications {
  a {
    color: $text;
    border-bottom: 2px $border solid;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 10px;
    padding-bottom: 10px;
  }
  .imageArea {
    border-radius: 5px;
    background-color: #fff;
    height: 70px;
    overflow: hidden;
    width: 70px;
    .image {
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        height: 100%;
        width: 100%;
    }
  }
  .text {
    width: calc(100% - 70px);
    padding-left: 10px;
    * {
      color: $text;
    }
    b {
      font-size: 16px;
      line-height: normal;
      font-weight: bold;
    }
  }
  .text_full {
    width: 100%;
    padding-left: 10px;
    * {
      color: $text;
    }
    b {
      font-size: 16px;
      line-height: normal;
      font-weight: bold;
    }
  }
}
.notifications_fixed {
  bottom: 0;
  left: 0;
  position: fixed;
  padding: 10px;
  width: calc(100% - 20px);
  z-index: 999;
  .areaNotification {
    align-items: center;
    display: flex;
    flex-direction: row;
    padding: 10px 5px;
    position: relative;
    transition: all 0.3s;
  }
  .close {
    color: #f0c901;
    font-size: 20px;
    font-weight: 400;
    padding: 10px;
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 999;
  }
  li {
    align-items: center;
    background-color: $primary;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
    position: relative;
    transition: all 0.3s;
    .imageArea {
      align-items: center;
      background-color: white;
      border-radius: 10px;
      display: flex;
      height: 80px;
      justify-content: center;
      width: 80px;
      .image {
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        height: 90%;
        width: 90%;
      }
    }
    .content_area {
      padding-left: 10px;
      width: 80%;
      .time {
        color: white;
        font-size: 14px;
        font-weight: 300;
        opacity: 0.8;
        margin-bottom: 5px;
      }
      .text {
        color: white;
        font-size: 13px;
        word-break: break-word;
        width: 100%;
        b {
          font-weight: bold;
        }
      }
    }
  }
  .notifications_remove {
    align-items: center;
    background-color: white;
    border: 1px #ccc solid;
    border-bottom: none;
    border-radius: 10px 10px 0 0;
    bottom: 0;
    display: flex;
    font-size: 14px;
    overflow: hidden;
    padding: 2px 8px;
    position: absolute;
    right: 10px;
    img {
      max-width: 28px;
      margin-right: 4px;
    }
  }
  .ocultar {
    display: none
  }
}