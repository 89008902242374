.menuArea {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.menuFull {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.menu {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 15px;
  width: 100%;
  img {
    max-height: 60px;
  }
}
.menuSacola {
  background-size: contain;
  background-position: center;
  height: 45px;
  width: 45px;
}
.menuPerfilArea {
  border-radius: 4px;
  background-color: #000;
  height: 42px;
  margin-left: 10px;
  overflow: hidden;
  width: 42px;
}
.menuPerfil {
  background-size: cover;
  background-position: center;
  height: 42px;
  width: 42px;
}
.logoArea {
  background-position: center;
  background-size: contain;
  max-width: 150px;
}
.menuFullArea {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.menuLoja {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 15px;
  padding-top: 15px;
  width: 100%;
  .menuBack {
    padding-left: 10px;
    margin-bottom: 8px;
    img {
      max-height: 30px;
      min-width: 35px;
    }
  }
  .titleArera {
    color: $primary;
    font-family: $font-family;
    font-size: 18px;
    margin-bottom: 8px;
    text-transform: uppercase;
    text-align: center;
  }
}
.menuBackArea {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 25px 10px;
  position: relative;
  width: 100%;
  .menuBack {
    position: absolute;
    left: 10px;
    top: 17px;
    img {
      max-height: 30px;
    }
  }
  .menuBackWhite {
    position: absolute;
    left: 10px;
    top: 17px;
    img {
      max-height: 30px;
      filter: invert(100%) brightness(100%);
    }
  }
  .titleArera {
    color: $primary;
    font-size: 18px;
    font-weight: 300;
    text-transform: uppercase;
    text-align: center;
    width: 100%;
  }
  .titleAreraWhite {
    color: white;
    font-size: 18px;
    margin-bottom: 8px;
    text-transform: uppercase;
    text-align: center;
  }
}
.menuFullWhite {
  background-color: $primary;
}
button:disabled {
  opacity: 0.8
}
.title_logo {
  align-items: center;
  display: flex;
  word-break: break-word;
}
.logo_loja {
  margin-right: 3px;
  max-width: 25px;
  max-height: 25px;
}
.left_title {
  min-width: 50%;
  text-align: left!important;
}