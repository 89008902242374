.pedidos {
  &Search {
    position: relative;
    width: 100%;
    input {
      border-radius: 5px;
      border: none;
      background-color: $input;
      color: $text;
      height: 40px;
      padding: 10px;
      width: 100%;
    }
    button {
      border: none;
      background: none;
      position: absolute;
      right: 5px;
      top: 8px;
      img {
        max-width: 20px;
      }
    }
  }
  .pedidosContent {
    margin-top: 20px;
    ul {
      margin-bottom: 10px;
    }
    li {
      margin-bottom: 15px;
    }
    .listaPedidosItem {
      box-shadow: 3px 4px 9px 1px rgba(0, 0, 0, 0.1);
      display: flex;
      flex-direction: row;
      padding: 20px 15px;
      .info {
        color: $text;
        font-size: 16px;
        text-align: left;
        width: 50%;
      }
      .codigo {
        color: $text;
        font-size: 16px;
        text-align: center;
        width: 20%;
      }
      .valor {
        position: relative;
        text-align: right;
        width: 30%;
        .valorTotal {
          color: $primary;
          font-size: 16px;
          font-weight: bold;
        }
        .hora {
          color: $secondary;
          font-size: 12px;
          bottom: -12px;
          position: absolute;
          right: 0;
        }
      }
    }
  }
  .title {
    font-weight: bold;
  }
}
.pedidosInterna {
  .title {
    font-weight: bold;
  }
  .qrcodeArea {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    width: 100%;
    img {
      max-height: 170px;
    }
    .code {
      height: 80px;
      margin-bottom: 15px;
      width: 80px;
    }
    .text {
      width: 100%;
    }
  }
  .timeline {
    align-items: center;
    border-top: 2px #e2e2e2 solid;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 25px;
    .one {
      position: relative;
      width: 25%;
      &:before {
        background-color: #e2e2e2;
        border-radius: 100%;
        content: '';
        height: 25px;
        position: absolute;
        left: calc(50% - 14px);
        top: -14px;
        width: 25px;
      }
      .timelineText {
        color: #e2e2e2;
        font-size: 14px;
        padding-top: 20px;
        text-align: center;
      }
    }
    .two {
      position: relative;
      width: 50%;
      &:before {
        background-color: #e2e2e2;
        border-radius: 100%;
        content: '';
        height: 25px;
        left: calc(50% - 14px);
        position: absolute;
        top: -14px;
        width: 25px;
      }
      .timelineText {
        color: #e2e2e2;
        padding-top: 20px;
        font-size: 14px;
        text-align: center;
      }
    }
    .three {
      position: relative;
      width: 25%;
      &:before {
        background-color: #e2e2e2;
        border-radius: 100%;
        content: '';
        height: 25px;
        left: calc(50% - 14px);
        position: absolute;
        top: -14px;
        width: 25px;
      }
      .timelineText {
        color: #e2e2e2;
        padding-top: 20px;
        font-size: 14px;
        text-align: center;
      }
    }
    .active-one {
      &:after {
        content: '';
        background-color: $primary;
        height: 2px;
        left: 0;
        position: absolute;
        top: -2px;
        width: 50%;
        z-index: 10;
      }
      &:before {
        background-color: $primary;
      }
      .timelineText {
        font-weight: bold;
        color: $primary;
      }
    }
    .active-two {
      &:after {
        content: '';
        background-color: $primary;
        height: 2px;
        left: -30%;
        position: absolute;
        top: -2px;
        width: 80%;
        z-index: 10;
      }
      &:before {
        background-color: $primary;
      }
      .timelineText {
        font-weight: bold;
        color: $primary;
      }
    }
    .active-three {
      &:after {
        content: '';
        background-color: $primary;
        height: 2px;
        left: -80%;
        position: absolute;
        top: -2px;
        width: 180%;
        z-index: 10;
      }
      &:before {
        background-color: $primary;
      }
      .timelineText {
        font-weight: bold;
        color: $primary;
      }
    }
  }
  .entregaArea {
    align-items: center;
    border-bottom: 2px $border solid;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    margin-top: 25px;
    padding-bottom: 10px;
  }
  .localSenha {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 10px;
    .imageArea {
      border-radius: 5px;
      background-color: #fff;
      height: 50px;
      margin-right: 10px;
      overflow: hidden;
      width: 50px;
      .image {
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        height: 100%;
        width: 100%;
      }
    }
    &Info {
      align-items: center;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }
  .detalhe {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 15px;
    margin-top: 15px;
  }
  .totalTaxaServico {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    .title {
      width: inherit;
      font-size: 15px;
    }
  }
  .totalDetalhe {
    align-items: center;
    border-bottom: 2px $border solid;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 15px;
    padding-bottom: 15px;
    .title {
      width: inherit;
    }
  }
  .productsList {
    li {
      align-items: center;
      display: flex;
      flex-direction: row;
      margin-bottom: 20px;
      .produtoArea {
        align-items: center;
        background-color: #fff;
        box-shadow: 3px 4px 9px 1px rgba(0, 0, 0, 0.1);
        height: 50px;
        justify-content: center;
        padding: 10px;
        overflow: hidden;
        width: 60px;
        img {
          max-height: 100%;
        }
      }
      .dadoProduto {
        padding-left: 15px;
        padding-right: 10px;
        width: calc(60% - 50px);
        .nomeProduto {
          color: $primary;
          margin-bottom: 10px;
        }
        .quantidadeArea {
          .quantidade {
            border: 1px $primary solid;
            border-radius: 5px;
            color: $primary;
            padding: 10px 10px;
            text-align: center;
            width: 20px;
          }
        }
      }
      .valorProduto {
        color: #000;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 0px;
        text-align: right;
        width: 40%;
      }
    }
  }
}
.mb_text {
  margin-bottom: 10px;
}
.cancelado {
  color: red;
}
.confirmado {
  color: green;
}
.oculto {
  opacity: 0;
}
.status_item_1 {
  border-left: 5px #ffc107 solid;
}
.status_item_2 {
  border-left: 5px #ffc107 solid;
}
.status_item_3 {
  border-left: 5px #007bff solid;
}
.status_item_4 {
  border-left: 5px #17a2b8 solid;
}
.status_item_5 {
  border-left: 5px #28a745 solid;
}
.status_item_6 {
  border-left: 5px #ffc107 solid;
}
.status_item_7 {
  border-left: 5px #dc3545 solid;
}
.status_item_8 {
  border-left: 5px #000000 solid;
}
.valor_riscado {
  font-size: 14px;
  font-weight: 400;
  text-decoration: line-through;
}
.no_bottom {
  margin-bottom: 0px!important;
}
.top_detalhe {
  margin-top: 30px;
}
.buttonNotFound {
  margin-top: 30px;
  text-align: center;
  button {
    color: white;
    font-size: 16px;
    font-weight: bold;
    background-color: $primary;
    border-radius: 5px;
    border: none;
    padding: 15px 10px;
    width: calc(100% - 30px);
  }
}
.other {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 35px;
  width: 100%;
  
  button {
    background-color: $primary;
    border-radius: 10px;
    color: white;
    font-size: 16px;
    border: 1px $primary solid;
    padding: 15px 30px 15px 30px;
  }
}