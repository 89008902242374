.qrcode {
  .title {
    color: $primary;
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 15px;
    text-align: center;
    width: 100%; 
  }
  .estabelecimento {
    color: $primary;
    font-weight: bold;
    font-size: 18px;
    margin-top: 15px;
    text-align: center;
    width: 100%; 
  }
}
.qrcodeReader {
  div {
    box-shadow: rgba(0, 0, 0, 1) 0px 0px 0px 5px inset!important;
  }
}
.version {
  bottom: 5px;
  color: #000;
  font-size: 12px;
  position: absolute;
  right: 5px;
}
.uppercase {
  text-transform: uppercase;
}
.buttonGo {
  background-color:$primary;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 16px;
  font-weight: 400;
  margin-top: 20px;
  padding: 15px;
}
.splashScreen {
  align-items: center;
  display: flex;
  background-color: $primary;
  height: calc(100vh);
  justify-content: center;
  margin-top: -75px;
  width: 100%;
  img {
    // filter: grayscale(1) brightness(99);
    max-width: 180px;
  }
}