.homeArea {
    padding: 15px;
    .homeTitle {
        color: $primary;
       font-weight: bold;
       font-size: 18px;
       margin-bottom: 15px;
       width: 100%;
    }
    &Categorias {
        width: 100%;
        ul {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 100%;
            li {
                margin-bottom: 10px;
                width: 25%;
                .imagem {
                    align-items: center;
                    border-radius: 5px;
                    display: flex;
                    height: 60px;
                    justify-content: center;
                    width: 100%;
                    img {
                        max-width: 30px;
                    }
                }
                .name {
                    color: $text;
                    margin-top: 10px;
                    text-align: center;
                    width: 100%;
                    word-wrap: break-word;
                }
            }
            .active {
                .imagem {
                    background-color: #000;
                    img {
                        filter: invert(100%) brightness(150%) contrast(100%);
                    }
                }
                .name {
                    color: $primary;
                    font-weight: bold;
                }
            }
        }
    }
    .destaqueArea {
        margin-top: 15px;
        .carouselDestaque {
            margin-left: -5px;
            .ImageCarousel {
                margin: 0 5px;
            }
        }
    }
    .lojasArea {
        margin-top: 15px;
        .lojasAreaList {
            ul {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;
                width: 100%;
                .disabled {
                    .image {
                        opacity: 0.7;
                        .status_loja {
                            opacity: 1;
                        }
                    }
                    .imageCover {
                        opacity: 0.7;
                        .status_loja {
                            opacity: 1;
                        }
                    }
                }
                li {
                    margin-bottom: 10px;
                    width: 31%;
                    .imageBox {
                        border-radius: 5px;
                        background-color: #fff;
                        box-shadow: 3px 4px 9px 1px rgba(0, 0, 0, 0.1);
                        height: 80px;
                        .image {
                            background-position: center;
                            background-repeat: no-repeat;
                            background-size: cover;
                            height: 100%;
                            position: relative;
                            overflow: hidden;
                            width: 100%;
                        }
                        .imageCover {
                            background-position: center;
                            background-repeat: no-repeat;
                            background-size: cover;
                            height: 100%;
                            position: relative;
                            overflow: hidden;
                            width: 100%;
                        }
                        .status_loja {
                            bottom: 0;
                            background-color: rgba(0,0,0,0.8);
                            color: white;
                            font-size: 16px;
                            padding: 8px 0px;
                            position: absolute;
                            left: 0;
                            text-align: center;
                            width: 100%;
                        }
                    }
                    .lojaNome {
                        color: $text;
                        margin-top: 10px;
                        text-align: center;
                        word-wrap: break-word;
                    }
                }
            }
        }
    }
}

.menuSearch {
    margin: 15px;
    position: relative;
    input {
      border-radius: 5px!important;
      border: none;
      background-color: $input;
      font-size: 15px;
      color: $text;
      height: 40px;
      padding: 10px 10px 10px 40px;
      width: 100%;
    }
    button {
      border: none;
      background: none;
      position: absolute;
      left: 5px;
      top: 10px;
      img {
        max-width: 18px;
      }
    }
  }
  .menuSearchArea {
    margin-bottom: 10px;
    padding: 0 10px;
    width: 100%;
  }
.qrcodeButton {
    position: relative;
    img {
        bottom: 90px;
        position: fixed;
        right: 10px;
    }
}
.homeAreaCategoriasNew {
    .imagem {
        align-items: center;
        border-radius: 5px;
        display: flex;
        height: 60px;
        justify-content: center;
        width: 100%;
        img {
            max-width: 30px;
        }
    }
    .name {
        color: $text;
        margin-top: 10px;
        text-align: center;
        width: 100%;
        word-wrap: break-word;
    }
    .active {
        .imagem {
            background-color: #000;
            img {
                filter: invert(100%) brightness(150%) contrast(100%);
            }
        }
        .name {
            color: $primary;
            font-weight: bold;
        }
    }
}
.destaquesSlider {
  margin-top: 20px;
  .imageBig {
    margin: 0 5px;
    border-radius: 5px;
    overflow: hidden;
    .imageBigItem {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 120px;
      width: 100%;
    }
  }
  .relativeItem {
    margin-left: -5px;
    position: relative;
  }
  .iconSelected {
    position: absolute;
    right: 10px;
    top: 5px;
    img {
      max-width: 25px;
    }
  }
}
